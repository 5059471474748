.App {
  text-align: center;
}

.App-logo {
  height: 40vmin;
  pointer-events: none;
}

@media (prefers-reduced-motion: no-preference) {
  .App-logo {
    animation: App-logo-spin infinite 20s linear;
  }
}

#rcorners1, .flip {
  border-radius: 25px;
  background: #D49941;
  padding: 20px;
  width: 300px;
  height: 300px;
  display: inline-block;
  position: fixed;
  top: 50%;
  bottom: 0;
  left: -25%;
  right: 0;
  margin: auto;
  opacity: 0.8;
}

#rcorners1, .register {
  border-radius: 25px;
  background: #D49941;
  padding: 20px;
  width: 300px;
  height: 300px;
  display: inline-block;
  position: fixed;
  top: 50%;
  bottom: 0;
  left: 25%;
  right: 0;
  margin: auto;
  opacity: 0.8;
}

.routes {
  border-radius: 25px;
  background: #D49941;
  padding: 20px;
  width: 300px;
  height: 600px;
  display: inline-block;
  position: fixed;
  top: 20%;
  bottom: 0;
  left: 70%;
  right: 0;
  margin: auto;
  opacity: 0.8;
}

.button {
}


.login {
  border-radius: 25px;
  background: lightgrey;
  padding: 20px;
  width: 300px;
  height: 300px;
  display: inline-block;
  position: fixed;
  top: 50%;
  bottom: 0;
  left: 0;
  right: 0;
  margin: auto;
  opacity: 0.7;
}


.empty {
  opacity: 1;
}

.App-header {
  background-color: #282c34;
  min-height: 100vh;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  font-size: calc(10px + 2vmin);
  color: white;
}

.App-link {
  color: #61dafb;
}

@keyframes App-logo-spin {
  from {
    transform: rotate(0deg);
  }
  to {
    transform: rotate(360deg);
  }
}

.container {
  color: white;
  margin: 5%;
}


.d-flex div{
}

.socialNetwork {
  display: inline-block;
  position: fixed;
  top: 0;
  bottom: 0;
  left: 0;
  right: 0;
  width: 200px;
  height: 100px;
  margin: auto;
}

.img {
  cursor: pointer;
  height: 180px;
  width: 180px;
  margin-left: 5%;
  margin-right: 5%;

}

.container::after {
  content: "";
  opacity: 0.9;

  background-image: url("https://a0.muscache.com/im/pictures/ad264cec-bd09-4e7c-9c12-08d59f56fcf9.jpg?aki_policy=xx_large");

  /* Full height */
  height: 100%;

  /* Center and scale the image nicely */
  background-position: center;
  background-repeat: repeat;
  background-size: cover;
  top: 0;
  left: 0;
  right: 0;
  bottom: 0;
  position: absolute;
  z-index: -1;
}
